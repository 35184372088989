import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

import { Form } from 'components/form/Form';
import { Container } from '../components/container/Container';
import { FormWrapper } from '../components/formWrapper/FormWrapper';
import SEO from '../components/seo/SEO';

export default () => {
  const intl = useIntl();

  return (
    <IntlContextConsumer>
      {({ language: lang }) => (
        <>
          <SEO title={intl.formatMessage({ id: 'contactSeoTitle' })}/>
          <Container>
            <FormWrapper child={
              <Form
                topText={intl.formatMessage({ id: 'firstHeader' })}
                secondText={intl.formatMessage({ id: 'secondHeader' })}
                lang={lang}
              />}
            />
          </Container>
        </>
      )}
    </IntlContextConsumer>
  );
};
